import {
  BadgeDollarSign,
  Building,
  Clock,
  FileText,
  LayoutDashboard,
  Mail,
  Package2,
  PieChart,
  TicketPercent,
  Users,
  Video,
  FileArchive,
  ClipboardPlus,
} from 'lucide-react';

export type TRoute = {
  url: string;
  icon: JSX.Element;
  title: string;
};

export const routes: { [key: string]: TRoute } = {
  INICIO: {
    title: 'Início',
    url: '/dashboard',
    icon: <LayoutDashboard />,
  },
  EMPRESA: {
    title: 'Empresa',
    url: '/dashboard',
    icon: <Building />,
  },
  PLANOS: {
    title: 'Planos',
    url: '/planos',
    icon: <FileText />,
  },
  CONVITES: {
    title: 'Convites',
    url: '/convites',
    icon: <Mail />,
  },
  USUARIOS: {
    title: 'Usuários',
    url: '/usuarios',
    icon: <Users />,
  },
  PENDENCIAS: {
    title: 'Pendencias',
    url: '/pendencias',
    icon: <Clock />,
  },
  PRODUTOS: {
    title: 'Produtos',
    url: '/produtos',
    icon: <Package2 />,
  },
  VENDAS: {
    title: 'Vendas',
    url: '/dashboard',
    icon: <BadgeDollarSign />,
  },
  RELATORIOS: {
    title: 'Relatórios',
    url: '/relatorios',
    icon: <PieChart />,
  },
  TELEMEDICINA: {
    title: 'Telemedicina',
    url: '/telemedicina',
    icon: <Video />,
  },
  DESCONTOS: {
    title: 'Descontos',
    url: '/descontos',
    icon: <TicketPercent />,
  },
  EXAMES: {
    title: 'Exames',
    url: '/dashboard',
    icon: <FileArchive />,
  },
  DOCUMENTOS: {
    title: 'Documentos',
    url: '/documentos',
    icon: <ClipboardPlus />,
  },
};
