import BVStyleguide from '@bv/styleguide';
import React from 'react';
import * as singleSpa from 'single-spa';

type TMenuItemProps = {
  title: string;
  url: string;
  icon: JSX.Element;
  isActive: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MenuItem({ title, url, icon, isActive, setIsOpen }: TMenuItemProps) {
  return (
    <button
      onClick={() => {
        if (setIsOpen) setIsOpen(false);
        singleSpa.navigateToUrl(url);
      }}
      className="mb-8 flex cursor-pointer items-center text-center max-[768px]:gap-3 md:mb-6 md:flex-col md:gap-2">
      <div
        className={BVStyleguide.cn(
          'bg-primary-light rounded-2 flex items-center justify-center p-2 transition-all ease-in-out max-[768px]:h-14 max-[768px]:w-14 md:h-12 md:w-12',
          isActive && 'bg-primary text-white',
        )}>
        {icon}
      </div>
      <span className={BVStyleguide.cn('text-gray-900 transition-all ease-in-out max-[768px]:text-lg', isActive && 'font-bold')}>{title}</span>
    </button>
  );
}
