import { TUser, TUserRoles } from "../../../types/user";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const tokenAtom = atomWithStorage<string | null>(
  "token",
  JSON.parse(localStorage.getItem("token")) || null
);

export const userAtom = atomWithStorage<TUser>(
  "user",
  (JSON.parse(localStorage.getItem("user")) as TUser) || ({} as TUser)
);

export const userViewTypeAtom = atomWithStorage<string | null>(
  "userViewType",
  (JSON.parse(localStorage.getItem("userViewType")) as TUserRoles) || null,
  {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);
      try {
        return JSON.parse(storedValue ?? "");
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
    subscribe(key, callback, initialValue) {
      function storageListener(e: StorageEvent) {
        console.log("STORAGE ALTERADO");
        if (e.storageArea === localStorage && e.key === key) {
          let newValue;
          try {
            newValue = JSON.parse(e.newValue ?? "");
          } catch {
            newValue = initialValue;
          }
          callback(newValue);
        }
      }

      window.addEventListener("storage", () => {
        console.log("STORAGE ALTERADO");
      });

      // Return the unsubscribe function
      return () => {
        window.removeEventListener("storage", storageListener);
      };
    },
  }
);
