import BVStyleguide from '@bv/styleguide';
import { logoBVAsset } from '@navigation-old/assets';
import { useLocation } from 'react-router-dom';
import { TRoute } from '../../consts/routes';
import { MenuItem } from './_components/MenuItem';
import { Menu, X } from 'lucide-react';
import { useState } from 'react';

export function Navigation({ userRoutes }: { userRoutes: TRoute[] }) {
  const location = useLocation();

  const { pathname } = location;

  return (
    <div className="rounded-2 fixed left-8 top-8 flex h-[calc(100vh-64px)] w-[144px] flex-col bg-white p-4">
      <img src={logoBVAsset} alt="Logo BV" className="mb-6 w-24" />
      {userRoutes.map((route, index) => (
        <MenuItem key={index} {...route} isActive={pathname === route?.url} />
      ))}
    </div>
  );
}

export function NavigationMobile({ userRoutes }: { userRoutes: TRoute[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { pathname } = location;

  return (
    <BVStyleguide.Drawer open={isOpen} direction="left">
      <BVStyleguide.DrawerTrigger
        onClick={() => setIsOpen(!isOpen)}
        className="bg-primary fixed bottom-4 left-4 z-10 flex h-12 w-12 items-center justify-center rounded-full">
        <Menu size={24} className="text-white" />
      </BVStyleguide.DrawerTrigger>
      <BVStyleguide.DrawerContent className="rounded-0 h-screen w-screen p-4">
        <img src={logoBVAsset} alt="Logo BV" className="mb-10 w-40 md:mb-6 md:w-24" />

        {userRoutes.map((route, index) => (
          <MenuItem key={index} {...route} setIsOpen={setIsOpen} isActive={pathname === route?.url} />
        ))}

        <BVStyleguide.DrawerFooter>
          {/* <Button>Submit</Button> */}
          <BVStyleguide.DrawerClose
            onClick={() => setIsOpen(!isOpen)}
            className="bg-primary fixed bottom-4 left-5 z-10 flex h-12 w-12 items-center justify-center rounded-full">
            <X size={24} className="text-white" />
          </BVStyleguide.DrawerClose>
        </BVStyleguide.DrawerFooter>
      </BVStyleguide.DrawerContent>
    </BVStyleguide.Drawer>
  );
}
