import { Navigation, NavigationMobile } from './components/Navigation';
import { logoBVAsset } from '@navigation-old/assets';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { routes } from '@navigation-old/consts/routes';
import { userViewTypeAtom } from '@navigation-old/atom/auth.atom';
import BVComponentsOld from '@bv/components-old';
import BVStyleguide from '@bv/styleguide';
import BVUtils from '@bv/utils';
import { useLocation } from 'react-router-dom';

export default function App(props) {
  const userViewType = useAtomValue(userViewTypeAtom);
  const decodedToken = BVUtils.token.getDecodedToken();
  const [isMobile, setIsMobile] = useState(false);

  const userRoutes = useMemo(() => {
    switch (userViewType) {
      case 'root':
        return [routes.EMPRESA, routes.PLANOS, routes.USUARIOS, routes.CONVITES, routes.PRODUTOS];

      case 'admin':
        //Se for admin e pertencer a ótica
        if (decodedToken.companyType === 'optics') {
          return [routes.VENDAS, routes.PRODUTOS];
        }
        return [routes.INICIO, routes.CONVITES, routes.USUARIOS];

      case 'employee':
        //Se for employee e pertencer a ótica
        if (decodedToken.companyType === 'optics') {
          return [routes.VENDAS, routes.PRODUTOS];
        }
        const newRoutes = [routes.INICIO, routes.DOCUMENTOS];
        if (decodedToken.modules.includes(BVComponentsOld.EPlanModules['Teleoftalmologia'])) {
          newRoutes.push(routes.TELEMEDICINA);
        }
        if (decodedToken.modules.includes(BVComponentsOld.EPlanModules['Desconto em óticas'])) {
          newRoutes.push(routes.DESCONTOS);
        }

        return newRoutes;

      case 'doctor':
        return [routes.EXAMES];

      default:
        return [];
    }
  }, [userViewType]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!userRoutes.length || userViewType === 'patient') return null;

  if (isMobile) return <NavigationMobile userRoutes={userRoutes} />;

  return <Navigation userRoutes={userRoutes} />;
}
